import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '@app/auth/login-dialog/login-dialog.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService as AppServiceLib } from '../../../../../core/src/lib/new-services/us-services';

@Injectable({
  providedIn: 'root'
})
export class AppService extends AppServiceLib {
  #dialog = inject(MatDialog);

  constructor() {
    super();
  }

  navigateToLogin(): Observable<boolean> {
    return this.#dialog.open(LoginDialogComponent).afterClosed()
      .pipe(
        map(res => !!res),
      );
  }
}
